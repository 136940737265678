import React from 'react'
import styles from './navigation.module.css'
import Link from 'gatsby-plugin-transition-link'

export default () => (
    <Link to="/">
      <nav className={styles.navigation} role="navigation">
          <svg style={{width: "110px", marginBottom: "-14px"}} viewBox="0 0 183 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.9509 16.7235L16.753 19.4239C16.3965 19.6913 15.9598 19.8962 15.4429 20.0388C14.926 20.1992 14.3824 20.2795 13.812 20.2795C11.8157 20.2795 10.2472 19.3971 9.1064 17.6325L6.35255 13.4082C6.06736 12.9626 5.77326 12.6506 5.47024 12.4724C5.16723 12.2942 4.83748 12.205 4.481 12.205H4.34731V20.0388H0.951782V1.05594H6.86054C9.26682 1.05594 11.0849 1.55502 12.3148 2.55318C13.5625 3.55134 14.1863 4.92381 14.1863 6.6706C14.1863 7.72223 13.9546 8.63127 13.4912 9.39771C13.0277 10.1463 12.395 10.7434 11.5929 11.1891C10.7908 11.6347 9.88176 11.9199 8.86577 12.0446C9.4005 12.3298 9.89067 12.8111 10.3363 13.4884L11.8335 15.7075C12.2257 16.2779 12.5911 16.67 12.9297 16.8839C13.2862 17.0978 13.7318 17.2048 14.2665 17.2048C14.8904 17.2048 15.4519 17.0443 15.9509 16.7235ZM6.61991 3.89H4.34731V9.47792H6.45949C7.92109 9.47792 8.99946 9.25512 9.6946 8.80951C10.3898 8.3639 10.7373 7.65093 10.7373 6.6706C10.7373 4.81687 9.36486 3.89 6.61991 3.89Z" fill="currentColor"/>
              <path d="M35.417 1.05594H38.866L38.8927 11.1623C38.9105 13.0517 38.5986 14.6826 37.9569 16.0551C37.3331 17.4097 36.4151 18.4525 35.2031 19.1833C34.0088 19.9141 32.5651 20.2795 30.8718 20.2795C28.1625 20.2795 26.1216 19.6021 24.7491 18.2475C23.3945 16.875 22.7171 14.7896 22.7171 11.9911V1.05594H26.1929V11.7238C26.1929 13.631 26.5761 15.0124 27.3425 15.8679C28.1268 16.7235 29.2854 17.1513 30.8183 17.1513C32.369 17.1513 33.5276 16.6522 34.294 15.654C35.0783 14.6559 35.4615 13.1052 35.4437 11.0019L35.417 1.05594Z" fill="currentColor"/>
              <path d="M60.4187 1.05594H63.7607V20.0388H60.7128L51.2748 6.80428V20.0388H47.9328V1.05594H50.9807L60.4187 14.2905V1.05594Z" fill="currentColor"/>
              <path d="M85.3713 1.05594H88.7134V20.0388H85.6654L76.2274 6.80428V20.0388H72.8854V1.05594H75.9333L85.3713 14.2905V1.05594Z" fill="currentColor"/>
              <path d="M95.6189 20.0388L103.72 1.05594H106.661L114.441 20.0388H110.912L109.201 15.7075H100.913L99.1481 20.0388H95.6189ZM102.116 12.7932H108.078L105.137 5.33377L102.116 12.7932Z" fill="currentColor"/>
              <path d="M121.459 20.0388V1.05594H124.935V9.5314L132.822 1.05594H137.046L128.116 10.5206C128.865 10.9128 129.507 11.5366 130.041 12.3922L132.18 15.7075C132.555 16.2779 132.92 16.67 133.277 16.8839C133.633 17.0978 134.079 17.2048 134.613 17.2048C135.255 17.2048 135.817 17.0443 136.298 16.7235L137.1 19.4239C136.761 19.6913 136.325 19.8962 135.79 20.0388C135.273 20.1992 134.729 20.2795 134.159 20.2795C132.145 20.2795 130.576 19.3971 129.453 17.6325L126.699 13.4082C126.45 13.0339 126.191 12.7487 125.924 12.5526C125.675 12.3387 125.345 12.1872 124.935 12.0981V20.0388H121.459Z" fill="currentColor"/>
              <path d="M143.514 20.0388V1.05594H146.989V9.5314L154.876 1.05594H159.101L150.171 10.5206C150.92 10.9128 151.561 11.5366 152.096 12.3922L154.235 15.7075C154.609 16.2779 154.975 16.67 155.331 16.8839C155.687 17.0978 156.133 17.2048 156.668 17.2048C157.31 17.2048 157.871 17.0443 158.352 16.7235L159.154 19.4239C158.816 19.6913 158.379 19.8962 157.844 20.0388C157.327 20.1992 156.784 20.2795 156.213 20.2795C154.199 20.2795 152.631 19.3971 151.508 17.6325L148.754 13.4082C148.504 13.0339 148.246 12.7487 147.978 12.5526C147.729 12.3387 147.399 12.1872 146.989 12.0981V20.0388H143.514Z" fill="currentColor"/>
              <path d="M173.207 20.2795C171.282 20.2973 169.633 19.9408 168.261 19.21C166.889 18.4614 165.837 17.383 165.106 15.9749C164.375 14.5668 164.01 12.8735 164.01 10.895C164.01 8.91646 164.402 7.16968 165.186 5.65461C165.971 4.12172 167.076 2.92749 168.502 2.07192C169.945 1.21636 171.648 0.788574 173.608 0.788574C175.355 0.788574 176.888 1.18071 178.207 1.96498C179.526 2.74925 180.551 3.84544 181.282 5.25356C182.012 6.64386 182.378 8.2837 182.378 10.1731C182.378 12.2229 181.977 14.0053 181.175 15.5204C180.39 17.0176 179.303 18.1851 177.913 19.0228C176.54 19.8606 174.972 20.2795 173.207 20.2795ZM173.1 17.1513C174.223 17.1513 175.213 16.875 176.068 16.3225C176.941 15.7699 177.619 14.9767 178.1 13.9429C178.599 12.9091 178.849 11.6525 178.849 10.1731C178.849 8.26587 178.367 6.74189 177.405 5.60114C176.442 4.46038 175.15 3.89 173.528 3.89C172.37 3.89 171.336 4.1841 170.427 4.77231C169.535 5.36051 168.831 6.17151 168.314 7.20533C167.798 8.23913 167.539 9.45119 167.539 10.8415C167.539 12.8556 168.029 14.4153 169.01 15.5204C170.008 16.6076 171.371 17.1513 173.1 17.1513Z" fill="currentColor"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M85.1043 35.5699C83.4389 35.3495 81.6076 35.6409 79.3801 37.2184L77.3572 34.3622C80.2673 32.3012 82.9782 31.7579 85.5636 32.1002C87.9569 32.417 90.1433 33.492 92.0357 34.4224C92.0896 34.4489 92.1432 34.4752 92.1966 34.5015C94.2298 35.5003 95.9584 36.324 97.7665 36.5456C99.4575 36.7529 101.334 36.4387 103.62 34.8194L105.643 37.6757C102.692 39.7659 99.9506 40.3396 97.3407 40.0196C94.8757 39.7175 92.6426 38.6202 90.7185 37.6749L90.6533 37.6428C88.6104 36.6392 86.8937 35.8068 85.1043 35.5699Z" fill="currentColor"/>
          </svg>
      </nav>
    </Link>
)
